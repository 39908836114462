import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Round } from "../../game/models/round";
import { Observable } from "rxjs";
import { first } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RoundResolve implements Resolve<Round> {
  resolve(route: ActivatedRouteSnapshot): Observable<Round> {
    const roundId = localStorage.getItem("ROUND_ID");

    if (!roundId) {
      return null;
    }

    return this.db
      .doc<Round>(`/rounds/${roundId}`)
      .valueChanges()
      .pipe(first());
  }
  constructor(private db: AngularFirestore) {}
}
